import React, { useState, useEffect } from 'react';
import img1 from "../images/Healthcare.svg";
import img2 from "../images/Banking.svg";
import img3 from "../images/Entertainent.svg";
import img4 from "../images/Manufacturing.svg";
import img5 from "../images/Ecom.svg";
import img6 from "../images/Eduction.svg";

function Industries() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); 

  return (
    <div>
      <div className="container mb-xs-4">
        <h1 className='mx-md-0 mx-3 mt-lg-5   text-center'>Industries We Serve</h1>
        <p className="text-muted mx-auto mt-md-0 mt-2 text-center fs-5 col-lg-10 col-12">
          Effortlessly manage your cloud and on-premises systems with our
          comprehensive support for infrastructure and applications.
        </p>
        
        <div className="mt-md-4 mt-4 d-md-block d-none">
          {/* Row for first 3 sectors */}
          <div className="row justify-content-center">
            <div className="col-md-2 col-12 bg-white text-center shadow-sm mb-4 mx-2">
              <img
                src={img1}
                alt="Healthcare"
                className="img-fluid col-md-4 col-5 mt-3"
              />
              <p className="pb-2">Healthcare</p>
            </div>
            <div className="col-md-2 col-12 bg-white text-center shadow-sm mb-4 mx-2">
              <img
                src={img2}
                alt="Banking"
                className="img-fluid col-md-4 col-5 mt-3"
              />
              <p className="pb-2">Banking</p>
            </div>
            <div className="col-md-2 col-12 bg-white text-center shadow-sm mb-4 mx-2">
              <img
                src={img3}
                alt="Entertainment"
                className="img-fluid col-md-4 col-5 mt-3"
              />
              <p className="mt-2">Entertainment</p>
            </div>
          </div>

          {/* Row for next 3 sectors */}
          <div className="row justify-content-center mt-4 mb-5">
            <div className="col-md-2 col-12 bg-white text-center shadow-sm mb-4 mx-2">
              <img
                src={img4}
                alt="Manufacturing"
                className="img-fluid col-md-4 col-5 mt-3"
              />
              <p className="mt-2 mb-2">Manufacturing</p>
            </div>
            <div className="col-md-2 col-12 bg-white text-center shadow-sm mb-4 mx-2">
              <img
                src={img6}
                alt="Education"
                className="img-fluid col-md-4 col-5 mt-3"
              />
              <p className="mt-2 mb-2">Education</p>
            </div>
            <div className="col-md-2 col-12 bg-white text-center shadow-sm mb-4 mx-2">
              <img
                src={img5}
                alt="E-commerce"
                className="img-fluid col-md-4 col-5 mt-2"
              />
              <p className="mt-2 mb-2">E-commerce</p>
            </div>
          </div>
        </div>

        {windowWidth < 480 && (
          <div className="mt-md-4 mt-4 d-md-none mb-4">
            {/* Mobile view with 2 items per row */}
            <div className="d-flex justify-content-between gap-2">
              <div className="col-6 bg-white text-center shadow-sm mb-3">
                <img
                  src={img1}
                  alt="Healthcare"
                  className="img-fluid col-md-4 col-5 mt-3"
                />
                <p className="pb-2">Healthcare</p>
              </div>
              <div className="col-6 bg-white text-center shadow-sm mb-3">
                <img
                  src={img2}
                  alt="Banking"
                  className="img-fluid col-md-4 col-5 mt-3"
                />
                <p className="pb-2">Banking</p>
              </div>
            </div>

            <div className="d-flex justify-content-between gap-2">
              <div className="col-6 bg-white text-center shadow-sm mb-3">
                <img
                  src={img3}
                  alt="Entertainment"
                  className="img-fluid col-md-4 col-5 mt-3"
                />
                <p className="mb-0 pb-2">Entertainment</p>
              </div>
              <div className="col-6 bg-white text-center shadow-sm mb-3">
                <img
                  src={img4}
                  alt="Manufacturing"
                  className="img-fluid col-md-4 col-5 mt-3"
                />
                <p className="mb-0">Manufacturing</p>
              </div>
            </div>

            <div className="d-flex justify-content-between gap-2">
              <div className="col-6 bg-white text-center shadow-sm mb-3">
                <img
                  src={img6}
                  alt="Education"
                  className="img-fluid col-md-4 col-5 mt-3"
                />
                <p className="mb-0 pb-2">Education</p>
              </div>
              <div className="col-6 bg-white text-center shadow-sm mb-3">
                <img
                  src={img5}
                  alt="E-commerce"
                  className="img-fluid col-md-4 col-5 mt-3"
                />
                <p className="mb-0 mt-2">E-commerce</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Industries;
