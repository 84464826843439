import React from 'react';
import './ML.css';
import img1 from "../images/Ben-1.svg";
import img2 from "../images/Ben-2.svg";
import img3 from "../images/Ben-3.svg";

export default function Mlbenefits() {
  return (
    <div className='container'>
      <div>
        <h1 className="text-center mt-lg-5 mt-4 font-weight-bold mb-3">
          Benefits of <span className="tColor">AI/ML</span>
        </h1>
      </div>

      {/* First row of cards */}
      <div className="equal-height-cards d-flex flex-wrap justify-content-center mb-lg-4 h-100">
        
        {/* Card 1 */}
        <div className="col-12 col-sm-6 col-md-4 mb-4 pe-lg-3">
          <div className="card rounded-5 p-2">
            <div className="d-flex">
              <img className="col-2 mt-2 ms-3 ben" src={img1} alt="AI Content" />
            </div>
            <div className="card-body d-flex flex-wrap">
              <h5 className='col-10'>Enhanced Efficiency and Productivity</h5>
              <p className='mt-2'>AI handles repetitive tasks, freeing your team to focus on strategic, creative work, boosting efficiency.</p>
            </div>
          </div>
        </div>

        {/* Card 2 */}
        <div className="col-12 col-sm-6 col-md-4 mb-4 pe-lg-3">
          <div className="card rounded-5 p-2">
            <div className="d-flex">
              <img className="col-2 mt-2 ms-3 ben" src={img2} alt="AI Content" />
            </div>
            <div className="card-body d-flex flex-wrap">
              <h5 className='col-8'>Improved Decision-Making</h5>
              <p className='mt-2'>AI quickly analyzes data, uncovering patterns and insights to support smarter, more informed decisions.</p>
            </div>
          </div>
        </div>

        {/* Card 3 */}
        <div className="col-12 col-sm-6 col-md-4 mb-4 pe-lg-0">
          <div className="card rounded-5 p-2">
            <div className="d-flex">
              <img className="col-2 mt-2 ms-3 ben" src={img3} alt="AI Content" />
            </div>
            <div className="card-body d-flex flex-wrap">
              <h5 className='col-10'>Personalized Customer Experiences</h5>
              <p >AI analyzes customer preferences to deliver tailored services, boosting engagement, satisfaction, and loyalty.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
