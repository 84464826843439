import React from 'react'
import ML1 from './ML1'
import Services from './Services'
import Mlbenefits from './Mlbenefits'
import WeOffer from './WeOffer'
import MLservices from './MLservices'
 function ML() {
  return (
    <div>
      <ML1 />
      <Services />
      <WeOffer />
      <Mlbenefits />
      <MLservices />
      
    </div>
  )
}
export default ML