import React from 'react'
import CardUi from '../components/Common/CardUi';
import { blockchainData } from '../components/Common/CardwebData';
import './Blockchain.css'
import { FaArrowTrendUp } from "react-icons/fa6";
import CssSwiper from '../components/Common/CssSwiper';
import { useState,useRef } from 'react';
import { useEffect } from 'react';
import { gsap } from "gsap";
import { useNavigate } from 'react-router-dom';
import Expertise from '../components/Common/Expertise';
import Static from './Static';

function Blockchain() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures the effect runs on

   useEffect(() => {
     gsap.to(logoRef.current, {
       y: () => document.querySelector(".scroll-container").clientHeight - 100,
       ease: "none",
       scrollTrigger: {
         trigger: ".scroll-container",
         start: "top top",
         end: "bottom bottom",
         scrub: true,
       },
     });
   }, []);
   const logoRef = useRef(null);

     const navigate = useNavigate();

     const handleContact = () => {
       navigate("/contact-us");
     };


  return (
    <div>
      {/* bannersection */}
      <div className="bannerrole d-md-block d-none ">
        <div className="d-flex flex-wrap  ">
          <div className="col-md-5 col-12  ">
            <img
              src={require("../images/blocksideban.png")}
              className="img-fluid  p-1 pb-md-0"
            />
          </div>
          <div className="col-md-7 col-12">
            <div className="blockchain-box">
              <h1 className=" textheadingweb">
                {/* Driving Excellence Through Customized Solutions */}
                Revolutionizing Business with{" "}
                <span className="texthead">
                  Decentralized Blockchain Technology
                </span>
              </h1>

              <p className="mt-md-4 textwebpara text-muted pforimacweb">
                Our Blockchain expertise covers everything from token
                development to security solutions, empowering businesses with
                decentralized tools to enhance operations and security.
              </p>
              <div className="d-flex">
                <div
                  className="btn border col-3  mt-md-4 rounded-5 abobutton"
                  onClick={handleContact}
                >
                  Contact us
                </div>
                <div onClick={handleContact}>
                  <img
                    src={require("../images/homeImages/aboutround.png")}
                    className="img-fluid  col-7 mt-md-4 "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {windowWidth < 480 && (
        <>
          <div className="">
            <div className="p-4 mx-3 pb-0">
              <h1 className="" style={{ textAlign: "center" }}>
                Revolutionizing Business with{" "}
                <span className="texthead">
                  Decentralized Blockchain Technology
                </span>
              </h1>
            </div>
            <div>
              <img
                src={require("../images/blocksideban.png")}
                className="img-fluid "
                alt="img"
              />
            </div>
            <p
              className="text-secondary px-2 mt-2 text-center"
              style={{ textAlign: "justify" }}
            >
              Our web development services provide scalable, secure, and fast
              solutions tailored to meet the needs of businesses in today's
              digital landscape. From design to deployment, we ensure your
              website remains modern and responsive, giving you a competitive
              edge online.
            </p>

            <div className="d-flex justify-content-center align-items-center mt-3 mb-4">
              <div
                className="btn border col-5  mt-md-4 rounded-5 abobutton"
                onClick={handleContact}
              >
                Contact us
              </div>
              <div onClick={handleContact}>
                <img
                  src={require("../images/homeImages/aboutround.png")}
                  className="img-fluid  col-7 mt-md-4 "
                />
              </div>
            </div>
          </div>
        </>
      )}

      <div
        className="scroll-container  d-md-block d-none position-relative"
        // style={{ height: "1650px", position: "relative" }}
      >
        <div
          style={{
            position: "absolute",
            left: "35px",
            top: "60px",
            bottom: "0px",
            width: "7px",
            backgroundColor: "#DAF0FF",
            zIndex: 1,
          }}
        >
          <div
            ref={logoRef}
            style={{
              width: "32px",
              height: "32px",
              backgroundColor: "#DAF0FF",
              borderRadius: "50%",
              position: "absolute",
              top: "0px",
              bottom: "0px",
              left: "-13px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#fff",
              fontWeight: "bold",
              zIndex: 2,
            }}
          >
            <img
              src={require("../images/datartswipe.png")}
              className="img-fluid"
            />
          </div>
        </div>

        {/* blockchanin */}
        <div className="content" style={{ marginLeft: "100px" }}>
          <div className="p-md-5 pb-md-0 p-3 mt-md-0 ">
            <h3>Blockchain Development</h3>
            <img
              src={require("../images/blockchainwebban.png")}
              alt="Webdev banner"
              className="col-12 pb-md-0"
            />
          </div>
          <CardUi dataarr={blockchainData} />
          {/* stats */}
           <Static/>
          {/* expertise */}
          <Expertise />
          {/* it ecosystem */}
        </div>
      </div>

      {/* for mob */}
      {windowWidth < 480 && (
        <>
          <div>
            <div className="p-md-5 pb-md-0 p-3 mt-2">
              <h3>Blockchain Development</h3>
              <img
                src={require("../images/blockchainwebban.png")}
                alt="Webdev banner"
                className="col-12 pb-md-0"
                style={{ height: "150px" }}
              />
            </div>
            <CardUi dataarr={blockchainData} />
            {/* stats */}
            <Static/>    
            {/* expertise */}
            <Expertise />
            {/* it ecosystem */}
          </div>
        </>
      )}
      {/* client */}
      <CssSwiper />
    </div>
  );
}

export default Blockchain