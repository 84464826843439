import React from 'react'
import GenAi from './GenAi';
import What from './What';
import Benefits from './Benefits';
import Cards from './Cards';
import Industries from './Industries';
import ML from './ML';



function GenerativeAI (){
    return(
        <>
        <div>
           
            <GenAi />
            <What />
            <Benefits />
            
            <Cards />
            <Industries />
            {/* <ML /> */}
        </div>
        
        
        </>
    )
}

export default GenerativeAI;