import React from 'react';
import './GenAI.css';
import img1 from "../images/Health.svg";
import img2 from "../images/Fintech.svg";
import img3 from "../images/Commerce.svg";
import img4 from "../images/E-Learning.svg";
import img5 from "../images/Real-Estate.svg";
import img6 from "../images/Fitness.svg";

export default function MLservices() {
    const data = [
        {
            heading: "Healthcare",
            img: img1,
            para: "AI enhances diagnostics, personalizes treatments, and streamlines patient care, improving outcomes and efficiency."
        },
        {
            heading: "Fintech",
            img: img2,
            para: "AI powers fraud detection, risk management, and personalized financial services, helping fintech stay ahead."
        },
        {
            heading: "E-commerce",
            img: img3,
            para: "AI optimizes inventory, personalizes shopping experiences, and boosts sales with smart recommendations, helping businesses thrive."
        },
        {
            heading: "E-learning",
            img: img4,
            para: "AI personalizes learning, boosts engagement, and automates admin tasks, making education more accessible and effective."
        },
        {
            heading: "Real Estate",
            img: img5,
            para: "AI delivers insights on property valuation, market trends, and customer preferences, helping professionals make smarter decisions."
        },
        {
            heading: "Fitness",
            img: img6,
            para: "AI powers personalized workouts, virtual coaching, and health monitoring, helping individuals achieve their fitness goals."
        }
    ];

    return (
        <div className='container background-service rounded-4 px-3 mb-lg-5'>
            <h1 className="text-center font-weight-bold mb-3 pt-5">
                Industries That <span className="tColor">AI</span> and <span className="tColor">ML Services</span> Excel At
            </h1>
            <p className="text-center mt-3 mx-auto col-11 fs-5 mb-5">
                At the heart of our AI solutions lies a deep understanding of diverse industries and their unique challenges. Our expertise spans several key sectors, enabling us to deliver tailored, impactful AI solutions that drive success.
            </p>

            <div className='pb-4'>
            <div className="d-flex flex-wrap justify-content-center gap-3 ">
                {data.map((item, index) => (
                    <div key={index} className="col-12 col-sm-6 col-md-5 card rounded-4 p-2 text-decoration-none">
                        <div className="card-body ">
                         <div className='d-flex flex-wrap justify-content-between align-items-center'>
                         <h5 className="col-9 text-start mb-0">{item.heading}</h5>
                       <div className='col-2'>
                       <img className="img-fluid" src={item.img}  />
                       </div>
                         </div>
                          
                                <p className='mt-3'>{item.para}</p>
                           
                        </div>
                    </div>
                ))}
            </div>
            </div>
            
        </div>
    );
}
