import React from 'react'
import CardUi from '../components/Common/CardUi';
import { IotData } from '../components/Common/CardwebData';
import CssSwiper from '../components/Common/CssSwiper';
import { FaArrowTrendUp } from "react-icons/fa6";
import icon from "../images/homeImages/icon-stats.png";
import { useState } from 'react';
import { useEffect ,useRef} from 'react';
import { gsap } from "gsap";
import { useNavigate } from 'react-router-dom';
import Expertise from '../components/Common/Expertise';
import Static from './Static';

function IOT() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures the effect runs on

  useEffect(() => {
    gsap.to(logoRef.current, {
      y: () => document.querySelector(".scroll-container").clientHeight - 100,
      ease: "none",
      scrollTrigger: {
        trigger: ".scroll-container",
        start: "top top",
        end: "bottom bottom",
        scrub: true,
      },
    });
  }, []);
  const logoRef = useRef(null);
  const navigate = useNavigate();

  const handleContact = () => {
    navigate("/contact-us");
  };

  return (
    <div>
      {/* bannersection */}
      <div className="bannerrole d-md-block d-none">
        <div className="d-flex flex-wrap align-items-center">
          <div className="col-md-7 col-12">
            <div className="webbanner-box ">
              <h1 className=" textheadingweb">
                {/* Driving Excellence Through Customized Solutions */}
                Empowering Innovation and Building Future with{" "}
                <span className="texthead"> IoT Technology</span>
              </h1>

              <p className="mt-md-4 textwebpara text-muted pforimacweb">
                From industrial IoT to wearable solutions, we deliver end-to-end
                IoT development that connects devices, optimizes operations, and
                drives business growth.
              </p>
              <div className="d-flex">
                <div
                  className="btn border col-3  mt-md-4 rounded-5 abobutton"
                  onClick={handleContact}
                >
                  Contact us
                </div>
                <div onClick={handleContact}>
                  <img
                    src={require("../images/homeImages/aboutround.png")}
                    className="img-fluid  col-7 mt-md-4 "
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-5 px-md-4 col-12 mt-md-4 ">
            <img
              src={require("../images/iotsideban.png")}
              className="img-fluid  p-md-4 pb-md-0"
            />
          </div>
        </div>
      </div>

      {windowWidth < 480 && (
        <>
          <div className="">
            <div className="p-3 mx-3 pb-0">
              <h1 className="" style={{ textAlign: "center" }}>
                Empowering Innovation and Building Future with{" "}
                <span className="texthead"> IoT Technology</span>
              </h1>
            </div>
            <div>
              <img
                src={require("../images/iotsideban.png")}
                className="img-fluid "
                alt="img"
              />
            </div>
            <p
              className="text-secondary px-4 mt-2"
              style={{ textAlign: "justify" }}
            >
              From industrial IoT to wearable solutions, we deliver end-to-end
              IoT development that connects devices, optimizes operations, and
              drives business growth.
            </p>

            <div className="d-flex justify-content-center align-items-center mt-3 mb-4">
              <div
                className="btn border col-5  mt-md-4 rounded-5 abobutton"
                onClick={handleContact}
              >
                Contact us
              </div>
              <div onClick={handleContact}>
                <img
                  src={require("../images/homeImages/aboutround.png")}
                  className="img-fluid  col-7 mt-md-4 "
                />
              </div>
            </div>
          </div>
        </>
      )}

      <div
        className="scroll-container d-md-block d-none position-relative"
        // style={{ height: "1650px", position: "relative" }}
      >
        <div
          style={{
            position: "absolute",
            left: "35px",
            top: "60px",
            bottom: "0px",
            width: "7px",
            backgroundColor: "#DAF0FF",
            zIndex: 1,
          }}
        >
          <div
            ref={logoRef}
            style={{
              width: "32px",
              height: "32px",
              backgroundColor: "#DAF0FF",
              borderRadius: "50%",
              position: "absolute",
              top: "0px",
              bottom: "0px",
              left: "-13px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#fff",
              fontWeight: "bold",
              zIndex: 2,
            }}
          >
            <img
              src={require("../images/datartswipe.png")}
              className="img-fluid"
            />
          </div>
        </div>
        {/* iot */}
        <div className="content" style={{ marginLeft: "100px" }}>
          <div className="p-md-5 pb-md-0 p-3">
            <h3>IoT Technology</h3>
            <img
              src={require("../images/iotbandev.png")}
              alt="Webdev banner"
              className="col-12 pb-md-0"
            />
          </div>
          <CardUi dataarr={IotData} />
          {/* stats */}
        <Static/>

          {/* expertise */}
          <Expertise />
          {/* it ecosystem */}
        </div>
      </div>


{/* for mobile */}
      {windowWidth < 480 && (
        <>
          <div>
            <div className="p-md-5 pb-md-0 p-3">
              <h3>IoT Technology</h3>
              <img
                src={require("../images/iotbandev.png")}
                alt="Webdev banner"
                className="col-12 pb-md-0"
                style={{ height: "150px" }}
              />
            </div>
            <CardUi dataarr={IotData} />
            {/* stats */}  
           <Static/>
            {/* expertise */}
            <Expertise />
            {/* it ecosystem */}
          </div>
        </>
      )}

      {/* client */}
      <CssSwiper />
    </div>
  );
}

export default IOT