import React from 'react';
import './WeOffer.css';
import img1 from "../images/python.svg";
import img2 from "../images/T-logo.svg";
import img3 from "../images/O-Logo.svg";
import img4 from "../images/K-logo.svg";
import img5 from "../images/AWS-logo.svg";
import img6 from "../images/Pur-logo.svg";
import img7 from "../images/Green-logo.svg";
import img8 from "../images/A-logo.svg";
import img9 from "../images/Star-logo.svg";
import img10 from "../images/Learn-logo.svg";
import img11 from "../images/Boost-logo.svg";
import img12 from "../images/S-logo.svg";
import img13 from "../images/Brain-logo.svg";
import img14 from "../images/Cbrain-logo.svg";
import img15 from "../images/Triangle-logo.svg";
import img16 from "../images/Yolo-logo.svg";
import img17 from "../images/Mask-logo.svg";
import img18 from "../images/E.eye-logo.svg";
import img19 from "../images/Eye-logo.svg";
import img20 from "../images/Path-logo.svg";
import img21 from "../images/AA-logo.svg";
import img22 from "../images/Prism-logo.svg";
import img23 from "../images/Arrow-logo.svg";
import img24 from "../images/IBM-logo.svg";
import img25 from "../images/W-logo.svg";
import img26 from "../images/Bird-logo.svg";
import img27 from "../images/R-studio-logo.svg";
import img28 from "../images/Hook- logo.svg";
import img29 from "../images/Plus-logo.svg";
import img30 from "../images/PowerBi-logo.svg";
import img31 from "../images/Funnel-logo.svg";

export default function WeOffer() {
  return (
    <div className='container '>
      <h1 className="text-center mt-4 font-weight-bold mb-4 ">
        AI/ML Solutions <span className="tColor">We Offer</span>
      </h1>

      {/* First row of cards */}
      <div className="d-flex flex-wrap mb-1">

        {/* Card 1 */}
        <div className="col-12 col-sm-6 col-md-8 mb-4 pe-lg-3">
          <div className="card rounded-5 p-2 text-decoration-none card-1-background card-equal-height">
            <div className="d-flex flex-wrap ms-2 ">
              <h4 className="col-12 mt-3 text-start">Custom AI Solutions</h4>
            </div>
            <div>
              <p className="col-lg-7 col-12 mt-3 text-start ms-2">
                As an AI development company, we create custom AI solutions tailored to your business needs. Our systems enhance efficiency, accuracy, and scalability, helping you streamline operations, make smarter decisions, and stay ahead of the competition.
              </p>
            </div>
            <div>
              <h5 className='mt-3 ms-2'>
                Technologies:
              </h5>
            </div>
            <div className='d-flex flex-wrap col-11 ms-1'>
              <img className="col-2 mt-2 image me-2 " src={img1} alt="AI Content" />
              <img className="col-2 mt-2 image me-2" src={img2} alt="AI Content" />
              <img className="col-2 mt-2 image me-2" src={img3} alt="AI Content" />
              <img className="col-2 mt-2 image me-2" src={img4} alt="AI Content" />
              <img className="col-2 mt-2 image me-2" src={img5} alt="AI Content" />
              <img className="col-2 mt-2 image me-2" src={img6} alt="AI Content" />
              <img className="col-2 mt-2 image me-2" src={img7} alt="AI Content" />
              <img className="col-2 mt-2 image me-2 " src={img8} alt="AI Content" />
              <img className="col-2 mt-2 image me-2" src={img9} alt="AI Content" />
            </div>
          </div>
        </div>

        {/* Card 2 */}
        <div className="col-12 col-sm-6 col-md-4 mb-4 px-lg-3 pe-lg-0">
          <div className="card rounded-5 p-2 text-decoration-none card-equal-height">
            <div className="d-flex flex-wrap">
              <h4 className="col-12 mt-3 text-start ms-2">Machine Learning Solutions</h4>
            </div>
            <div>
              <p className="mt-3 text-start ms-2">
                Integrate advanced machine learning into your applications to automate tasks, gain insights, and improve decision-making. Our solutions help you uncover patterns and trends that drive business growth.
              </p>
            </div>
            <div>
              <h5 className='mt-3 ms-2'>
                Technologies:
              </h5>
            </div>
            <div className='d-flex flex-wrap mb-2 '>
              <img className="col-2 mt-2 image me-2 " src={img2} alt="AI Content" />
              <img className="col-2 mt-2 image me-2" src={img3} alt="AI Content" />
              <img className="col-2 mt-2 image me-2" src={img10} alt="AI Content" />
              <img className="col-2 mt-2 image me-2" src={img11} alt="AI Content" />
              <img className="col-2 mt-2 image me-2" src={img12} alt="AI Content" />
              <img className="col-2 mt-2 image me-2" src={img13} alt="AI Content" />
              <img className="col-2 mt-2 image me-2" src={img14} alt="AI Content" />
            </div>
          </div>
        </div>

        {/* Card 3 */}
        <div className="col-12 col-sm-6 col-md-4 mb-4 pe-lg-3">
          <div className="card rounded-5 p-2 text-decoration-none card-equal-height">
            <div className="d-flex flex-wrap">
              <h4 className="col-12 mt-3 text-start ms-2">Computer Vision</h4>
            </div>
            <div>
              <p className="mt-3 text-start ms-2">
                Implement computer vision to analyze images and videos with AI-powered recognition and detection. Automate inspections, enhance security, and improve customer experiences.
              </p>
            </div>
            <div>
              <h5 className='mt-3 ms-2'>
                Technologies:
              </h5>
            </div>
            <div className='d-flex flex-wrap mb-2'>
              <img className="col-2 mt-2 image me-2 " src={img15} alt="AI Content" />
              <img className="col-2 mt-2 image me-2" src={img2} alt="AI Content" />
              <img className="col-2 mt-2 image me-2" src={img3} alt="AI Content" />
              <img className="col-2 mt-2 image me-2" src={img16} alt="AI Content" />
              <img className="col-2 mt-2 image me-2" src={img17} alt="AI Content" />
              <img className="col-2 mt-2 image me-2" src={img18} alt="AI Content" />
              <img className="col-2 mt-2 image me-2" src={img19} alt="AI Content" />
            </div>
          </div>
        </div>

        {/* Card 4 */}
        <div className="col-12 col-sm-6 col-md-8 mb-4 px-lg-3 pe-lg-0">
          <div className="card rounded-5 p-2 text-decoration-none card-4-background card-equal-height">
            <div className="d-flex flex-wrap">
              <h4 className="col-12 mt-3 text-start ms-2">Predictive Analytics</h4>
            </div>
            <div>
              <p className="col-lg-7 col-12 mt-3 text-start ms-2">
                Use predictive analytics to forecast trends, seize opportunities, and reduce risks. Make proactive, data-driven decisions for better efficiency and strategy.
              </p>
            </div>
            <div>
              <h5 className='mt-3 ms-2'>
                Technologies:
              </h5>
            </div>
            <div className='d-flex flex-wrap col-lg-6 col-12 ms-2'>
              <img className="col-2 mt-1 image me-2 " src={img27} alt="AI Content" />
              <img className="col-2 mt-1 image me-2" src={img1} alt="AI Content" />
              <img className="col-2 mt-1 image me-2" src={img28} alt="AI Content" />
              <img className="col-2 mt-1 image me-2" src={img29} alt="AI Content" />
              <img className="col-2 mt-1 image me-2" src={img30} alt="AI Content" />
              <img className="col-2 mt-1 image me-2" src={img2} alt="AI Content" />
              <img className="col-2 mt-1 image me-2" src={img3} alt="AI Content" />
              <img className="col-2 mt-1 image me-2 " src={img5} alt="AI Content" />
              <img className="col-2 mt-1 image me-2 " src={img9} alt="AI Content" />
              <img className="col-2 mt-1 image me-2" src={img31} alt="AI Content" />
            </div>
          </div>
        </div>

        {/* Card 5 */}
        <div className="col-12 col-sm-6 col-md-6 mb-4 pe-lg-3">
          <div className="card rounded-5 p-2 text-decoration-none card-5-background card-equal-height">
            <div className="d-flex flex-wrap">
              <h4 className="col-12 mt-3 text-start ms-2">AI & ML Consulting and Strategy</h4>
            </div>
            <div>
              <p className="col-10 mt-3 text-start ms-2">
                Get expert AI guidance on strategy, technology selection, and implementation. We ensure seamless integration to help you achieve business goals and maximize ROI.
              </p>
            </div>
          </div>
        </div>

        {/* Card 6 */}
        <div className="col-12 col-sm-6 col-md-6 mb-lg-4 px-lg-3 pe-lg-0">
          <div className="card rounded-5 p-2 text-decoration-none card-equal-height">
            <div className="d-flex flex-wrap">
              <h4 className="col-12 mt-3 text-start ms-2">Robotic Process Automation (RPA)</h4>
            </div>
            <div>
              <p className="mt-3 text-start ms-2">
              Automate repetitive tasks with RPA to boost efficiency and accuracy. Free your team to focus on high-value work while streamlining operations.
              </p>
            </div>
            <div>
              <h5 className='mt-2 ms-2'>
                Technologies:
              </h5>
            </div>
            <div className="d-flex flex-wrap ms-2">
            <img className="col-2 mt-2 me-2 image" src={img20} alt="AI Content" />
            <img className="col-2 mt-2 me-2 image" src={img21} alt="AI Content" />
            <img className="col-2 mt-2 me-2 image" src={img22} alt="AI Content" />
            <img className="col-2 mt-2 me-2 image" src={img23} alt="AI Content" />
              <img className="col-2 mt-2 me-2 image" src={img24} alt="AI Content" />
              <img className="col-2 mt-2 me-2 image" src={img25} alt="AI Content" />
              <img className="col-2 mt-2 me-2 image" src={img26} alt="AI Content" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
