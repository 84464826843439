import React, { useEffect, useState } from "react";
import "./Header.css";
import img1 from "../images/AI.svg";


import { Container, Nav, Navbar, Offcanvas, Dropdown } from "react-bootstrap";
import Logo from "../images/logo-light.png";
import LogoImg from "../images/Logo2.png";
import { Link,useLocation } from "react-router-dom";
import { FaArrowRightLong } from "react-icons/fa6";
import Solutiondrop from "./Common/Solutiondrop";
import { BiHomeAlt2 } from "react-icons/bi";
import { GrTechnology } from "react-icons/gr";
import { PiBuildings } from "react-icons/pi";
import { LiaPhoneSquareSolid } from "react-icons/lia";
import { HiOutlineRectangleGroup } from "react-icons/hi2";
import { LiaProjectDiagramSolid } from "react-icons/lia";
import { LiaInfoSolid } from "react-icons/lia";
import { IoCallOutline } from "react-icons/io5";



export default function Header() {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const location = useLocation()
  const currentPath = location.pathname;
  useEffect(() => {
    console.log("currentPath",currentPath)
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [location.pathname]); // Empty dependency array ensures the effect runs only once after initial render
  // explore all technologies

  const [showDropdown, setShowDropdown] = useState(false);
  const toggleDropdown = () => {
    setShowDropdown((prevState) => !prevState);
  };
  const handleMouseEnter = () => {
    setShowDropdown(true);
  };

  const handleMouseLeave = () => {
    setShowDropdown(false);
  };


   const [showOffcanvas, setShowOffcanvas] = useState(false);
     const toggleOffcanvas = () => setShowOffcanvas(!showOffcanvas);

     const closeOffcanvas = () => setShowOffcanvas(false);

     const servicePaths = [
       "/webdevelopment",
       "/mobiledevelopment",
       "/customdevelopment",
       "/iot",
       "/blockchain",
       "/digitalmarketing",
       "/uiux",
       "/cloudservices",
       "/itconsultant",
       "/ml",
      //  "/ai"
     ];
     
  return (
    <div className="header">
      {/* <div className='container'> */}
      {/* <div
        className={`${
          windowWidth > 2519 ? "container" : "container"
        }`}
      > */}
      <Navbar expand="lg">
        <>
          {windowWidth > 2520 ? (
            <>
              <Link to="/" className="brand-logo py-2 navbar-brand mx-md-3">
                <img
                  alt=""
                  src={LogoImg}
                  id="logo"
                  width="64px"
                  height="64px"
                  className="me-md-3 me-2 rounded-3 "
                />
                <img
                  id="datart"
                  src={Logo}
                  alt="Logo"
                  height="auto"
                  width="128px"
                />
              </Link>
            </>
          ) : (
            <>
              <Link to="/" className="brand-logo py-2 navbar-brand">
                <img
                  alt=""
                  src={LogoImg}
                  id="logo"
                  width="64px"
                  height="64px"
                  className="me-md-3 me-2 rounded-3 "
                />
                <img
                  id="datart"
                  src={Logo}
                  alt="Logo"
                  height="auto"
                  width="100%"
                />
              </Link>
            </>
          )}

          <Navbar.Toggle
            aria-controls={`offcanvasNavbar-expand-lg`}
            id="CloseFunction"
            onClick={toggleOffcanvas}
            className="border-0"
          />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-lg`}
            aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
            placement="end"
            className="bgformob"
            show={showOffcanvas}
            onHide={closeOffcanvas}
          >
            {/* <Offcanvas.Header closeButton></Offcanvas.Header> */}
            <Offcanvas.Body className="xyz">
              <Nav className="justify-content-end flex-grow-1 pe-4">
                <Link
                  id="Home"
                  className={`${
                    currentPath === "/" ? "active " : ""
                  } nav-link secondary-txt me-3 d-md-block d-none forhover`}
                  to="/"
                >
                  Home
                </Link>
                <div className="d-md-none d-block d-flex justify-content align-items-center forhover-parent">
                  <BiHomeAlt2
                    size={25}
                    className="arrow-icon"
                    style={{
                      color: location.pathname === "/" ? "#0FA4D2" : "black",
                    }}
                  />
                  <Link
                    id="Home"
                    className={`${
                      currentPath === "/" ? "active " : ""
                    } nav-link secondary-txt me-3 mx-3  nav-link `}
                    to="/"
                    onClick={closeOffcanvas}
                  >
                    Home
                  </Link>
                </div>
                {/* <Link
                    id="Solutions"
                    className={`${
                      currentPath === "/solutions" ? "active" : ""
                    } nav-link secondary-txt me-3`}
                    to="/solutions"
                  >
                    Solutions
                  </Link> */}

                <Link
                  id="AI"
                  className={`${
                    currentPath === "/ai" ? "active" : ""
                  } nav-link secondary-txt me-3 d-none d-md-block forhover`}
                  to="/ai"
                >
                  GenAI
                </Link>

                <div className="d-md-none d-block d-flex align-items-center ">
                  <img src={img1}
                    size={25}
                    className="arrow-icon"
                    style={{
                      color:
                        location.pathname === "/ai" ? "#0FA4D2" : "black",
                    }}
                  />
                  <Link
                    id="AI"
                    className={`${
                      currentPath === "/ai" ? "active" : ""
                    } nav-link secondary-txt me-3 mx-3 `}
                    to="/ai"
                    onClick={closeOffcanvas}
                  >
                    GenAI
                  </Link>
                </div>

                <Dropdown
                  className="postition-relative d-none d-md-block"
                  show={showDropdown}
                  onClick={toggleDropdown}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <Dropdown.Toggle
                    id="Solutions"
                    className={`${
                      servicePaths.includes(currentPath) ? "active" : ""
                    } nav-link secondary-txt me-3  d-flex align-items-center justify-content-center custom-dropdown-toggle`}
                    variant="link"
                  >
                    Services
                  </Dropdown.Toggle>
                  {showDropdown && (
                    <Dropdown.Menu className=" border-0  position-absolute d-md-block d-none">
                      <Solutiondrop />
                    </Dropdown.Menu>
                  )}
                </Dropdown>
                {/* formob */}
                <div className="d-flex">
                  <LiaProjectDiagramSolid
                    size={25}
                    className="fixed-icon mt-2 d-md-none "
                    style={{
                      color: servicePaths.includes(currentPath)
                        ? "#0FA4D2"
                        : "black",
                    }}
                  />

                  <div className="d-md-none d-flex align-items-center position-relative">
                    <div className="d-flex align-items-center">
                      <Dropdown
                        className="postition-relative mx-2"
                        show={showDropdown}
                        onClick={toggleDropdown}
                        // onMouseEnter={handleMouseEnter}
                        // onMouseLeave={handleMouseLeave}
                      >
                        <Dropdown.Toggle
                          id="Solutions"
                          className={`${
                            servicePaths.includes(currentPath) ? "active" : ""
                          } nav-link secondary-txt me-3  d-flex align-items-center justify-content-center custom-dropdown-toggle`}
                          variant="link"
                        >
                          Services
                        </Dropdown.Toggle>
                        {/* for mobile */}
                        {showDropdown && (
                          <div className="d-md-none d-block  col-12">
                             <div className="col-12 mt-2">
                              <div className="d-flex align-items-center  gap-2 mt-md-3 ">
                                <FaArrowRightLong size={22} />
                                <Link
                                  as={Link}
                                  to="/customdevelopment"
                                  className="text-decoration-none text-black  "
                                  onClick={closeOffcanvas}
                                >
                                  Custom Software Development
                                </Link>
                              </div>
                            </div>

                            <div className="col-12  mt-2 mb-2">
                              <div className="d-flex align-items-center  gap-2 mt-md-4">
                                <FaArrowRightLong />
                                
                                <Link
                                  as={Link}
                                  to="/ml"
                                  className="text-decoration-none text-black"
                                  onClick={closeOffcanvas}
                                >
                                  AI/ML
                                </Link>
                              </div>
                            </div>

                            <div className="col-12  mt-2">
                              <div className="d-flex align-items-center  gap-2 mt-md-3">
                                <FaArrowRightLong />
                                <Link
                                  as={Link}
                                  to="/webdevelopment"
                                  className="text-decoration-none text-black "
                                  onClick={closeOffcanvas}
                                >
                                  Web Development
                                </Link>
                              </div>
                            </div>
                            <div className="col-12  mt-2">
                              <div className="d-flex align-items-center  gap-2 mt-md-3">
                                <FaArrowRightLong />
                                <Link
                                  as={Link}
                                  to="/mobiledevelopment"
                                  className="text-decoration-none text-black  "
                                  onClick={closeOffcanvas}
                                >
                                  Mobile Development
                                </Link>
                              </div>
                            </div>

                            <div className="col-12  mt-2">
                              <div className="d-flex align-items-center  gap-2 mt-md-3">
                                <FaArrowRightLong />
                                <Link
                                  as={Link}
                                  to="/blockchain"
                                  className="text-decoration-none text-black"
                                  onClick={closeOffcanvas}
                                >
                                  Blockchain Development
                                </Link>
                              </div>
                            </div>
                           
                            <div className="col-12 mt-2">
                              <div className="d-flex align-items-center  gap-2 mt-md-3 ">
                                <FaArrowRightLong />
                                <Link
                                  as={Link}
                                  to="/iot"
                                  className="text-decoration-none text-black  "
                                  onClick={closeOffcanvas}
                                >
                                  Internet of Things(IoT)
                                </Link>
                              </div>
                            </div>
                            
                            <div className="col-12  mt-2">
                              <div className="d-flex align-items-center  gap-2 mt-md-3">
                                <FaArrowRightLong />
                                <Link
                                  as={Link}
                                  to="/cloudservices"
                                  className="text-decoration-none text-black"
                                  onClick={closeOffcanvas}
                                >
                                  Cloud Services
                                </Link>
                              </div>
                            </div>

                            <div className="col-12  mt-2">
                              <div className="d-flex align-items-center  gap-2 mt-md-3">
                                <FaArrowRightLong />
                                <Link
                                  as={Link}
                                  to="/uiux"
                                  className="text-decoration-none text-black"
                                  onClick={closeOffcanvas}
                                >
                                  UI/UX Design
                                </Link>
                              </div>
                            </div>

                            <div className="col-12  mt-2">
                              <div className="d-flex align-items-center gap-2 mt-md-3">
                                <FaArrowRightLong />
                                <Link
                                  as={Link}
                                  to="/digitalmarketing"
                                  className="text-decoration-none text-black"
                                  onClick={closeOffcanvas}
                                >
                                  Digital Marketing
                                </Link>
                              </div>
                            </div>
                            
                            
                            <div className="col-12  mt-2 mb-2">
                              <div className="d-flex align-items-center  gap-2 mt-md-4">
                                <FaArrowRightLong />
                                <Link
                                  as={Link}
                                  to="/itconsultant"
                                  className="text-decoration-none text-black"
                                  onClick={closeOffcanvas}
                                >
                                  IT Consultation
                                </Link>
                              </div>
                            </div>

                            
                          </div>
                        )}
                      </Dropdown>
                    </div>
                  </div>
                </div>

                {/* <Link
                  id="AI"
                  className={`${
                    currentPath === "/ai" ? "active" : ""
                  } nav-link secondary-txt me-3 d-none d-md-block forhover`}
                  to="/ai"
                >
                  GenerativeAI
                </Link> */}

                {/* <div className="d-md-none d-block d-flex align-items-center ">
                  <LiaInfoSolid
                    size={25}
                    className="arrow-icon"
                    style={{
                      color:
                        location.pathname === "/ai" ? "#0FA4D2" : "black",
                    }}
                  />
                  <Link
                    id="AI"
                    className={`${
                      currentPath === "/ai" ? "active" : ""
                    } nav-link secondary-txt me-3 mx-3 `}
                    to="/ai"
                    onClick={closeOffcanvas}
                  >
                    GenerativeAI
                  </Link>
                </div> */}

                <Link
                  id="About"
                  className={`${
                    currentPath === "/about-us" ? "active" : ""
                  } nav-link secondary-txt me-3 d-none d-md-block forhover`}
                  to="/about-us"
                >
                  About Us
                </Link>

                <div className="d-md-none d-block d-flex align-items-center ">
                  <LiaInfoSolid
                    size={25}
                    className="arrow-icon"
                    style={{
                      color:
                        location.pathname === "/about-us" ? "#0FA4D2" : "black",
                    }}
                  />
                  <Link
                    id="About"
                    className={`${
                      currentPath === "/about-us" ? "active" : ""
                    } nav-link secondary-txt me-3 mx-3 `}
                    to="/about-us"
                    onClick={closeOffcanvas}
                  >
                    About Us
                  </Link>
                </div>

                <Link
                  id="Portfolio"
                  className={`${
                    currentPath === "/portfolio" ? "active" : ""
                  } nav-link secondary-txt me-3 d-md-block d-none forhover`}
                  to="/portfolio"
                >
                  Portfolio
                </Link>
                <div className="d-flex align-items-center d-md-none ">
                  <HiOutlineRectangleGroup
                    size={25}
                    className="arrow-icon"
                    style={{
                      color:
                        location.pathname === "/portfolio"
                          ? "#0FA4D2"
                          : "black",
                    }}
                  />
                  <Link
                    id="Portfolio"
                    className={`${
                      currentPath === "/portfolio" ? "active" : ""
                    } nav-link secondary-txt me-3 mx-3 `}
                    to="/portfolio"
                    onClick={closeOffcanvas}
                  >
                    Portfolio
                  </Link>
                </div>

                {/* <Link
                  id="blogs"
                  className={`${
                    currentPath === "/blogs" ? "active" : ""
                  } nav-link secondary-txt me-3 d-md-block d-none forhover`}
                  to="/blogs"
                >
                  Blogs
                </Link>
                <div className="d-flex align-items-center d-md-none ">
                  <HiOutlineRectangleGroup
                    size={25}
                    className="arrow-icon"
                    style={{
                      color:
                        location.pathname === "/blogs"
                          ? "#0FA4D2"
                          : "black",
                    }}
                  />
                  <Link
                    id="Portfolio"
                    className={`${
                      currentPath === "/blogs" ? "active" : ""
                    } nav-link secondary-txt me-3 mx-3 `}
                    to="/blogs"
                    onClick={closeOffcanvas}
                  >
                    Blogs
                  </Link>
                </div> */}

                <Link
                  id="Careers"
                  className={`${
                    currentPath === "/careers" ||
                    currentPath === "/careers/open-position"
                      ? "active"
                      : ""
                  } nav-link secondary-txt me-3 d-md-block d-none forhover`}
                  to="/careers"
                >
                  Careers
                </Link>
                <div className="d-flex align-items-center d-md-none ">
                  <PiBuildings
                    size={25}
                    className="arrow-icon"
                    style={{
                      color:
                        location.pathname === "/careers" ? "#0FA4D2" : "black",
                    }}
                  />

                  <Link
                    id="Careers"
                    className={`${
                      currentPath === "/careers" ||
                      currentPath === "/careers/open-position"
                        ? "active"
                        : ""
                    } nav-link secondary-txt me-3 mx-3`}
                    to="/careers"
                    onClick={closeOffcanvas}
                  >
                    Careers
                  </Link>
                </div>
                <Link
                  id="Contact"
                  className={`${
                    currentPath === "/contact-us" ? "active" : ""
                  } nav-link secondary-txt me-3 d-md-block d-none forhover`}
                  to="/contact-us"
                >
                  Contact Us
                </Link>
                <div className="d-flex align-items-center d-md-none">
                  <IoCallOutline
                    size={23}
                    className="arrow-icon"
                    style={{
                      color:
                        location.pathname === "/contact-us"
                          ? "#0FA4D2"
                          : "black",
                    }}
                  />
                  <Link
                    id="Contact"
                    className={`${
                      currentPath === "/contact-us" ? "active" : ""
                    } nav-link secondary-txt me-3 mx-3`}
                    to="/contact-us"
                    onClick={closeOffcanvas}
                  >
                    Contact Us
                  </Link>
                </div>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </>
      </Navbar>
      {/* </div> */}
    </div>
  );
}
