import React from 'react';
import './GenAI.css';
import img1 from "../images/Overlay.svg";
import img2 from "../images/Chatbot.svg";
import img3 from "../images/Powered.svg";
import img4 from "../images/Driven.svg";
import img5 from "../images/AI-Finance.svg";
import img6 from "../images/AI-Video.svg";

export default function Cards() {

  const data = [
    {
      img: img1,
      heading: "AI-Powered Content Generation",
      para: "Create high-quality, engaging content in seconds, from blogs to marketing copy, tailored to your audience."
    },
    {
      img: img2,
      heading: "AI Chatbots & Virtual Assistants",
      para: "Enhance customer engagement and streamline support with intelligent AI-driven conversational experiences."
    },
    {
      img: img3,
      heading: "AI-Powered Data Analytics & Insights",
      para: "Unlock deep business insights with AI-driven data analysis, trend forecasting, and decision-making."
    },
    {
      img: img4,
      heading: "AI-Driven Healthcare & Medical Applications",
      para: "Enhance diagnostics, automate reports, and personalize patient care with AI-powered solutions."
    },
    {
      img: img5,
      heading: "AI-Powered Financial & Legal Document Automation",
      para: "Simplify paperwork, reduce errors, and save time with smart document solutions."
    },
    {
      img: img6,
      heading: "AI-Powered Image & Video Generation",
      para: "Generate stunning visuals and videos instantly, from marketing creatives to realistic AI-generated content."
    },
  ]

  return (
    <div className='new-background'>
      <div className="container text-center">
        <h1 className="mt-4 font-weight-bold">
          Extensive Catalog of Tailored <span className="tColor">Solutions</span>
        </h1>
        <p className="mt-3 fs-5 col-lg-8 mx-auto">
          Each solution is meticulously crafted to suit your specific requirements. Our offerings can elevate your business to new heights.
        </p>

        <div className="d-flex flex-wrap justify-content-center gap-3 mt-4">
          {data?.map((item, index) => (
            <div className='col-12 col-sm-6 col-md-4 col-lg-3'>
              <div key={index} className="card card-anim p-2 h-100">
                <div className="d-flex justify-content-center">
                  <img className="mt-2" src={item?.img} alt="AI Content" />
                </div>
                <div className="card-body text-center border-0 card-anim">
                  <h5 className='text-size mt-lg-0 mt-3'>{item?.heading}</h5>
                  <p className='mt-lg-0 mt-3'>{item?.para}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
