import img1 from "../../images/iconfirst.png";
import img2 from "../../images/iconsecond.png";
import img3 from "../../images/iconthree.png";
import img4 from "../../images/iconfour.png";
import img5 from "../../images/iconfive.png"
import img6 from "../../images/iconsix.png"
import img7 from "../../images/iconseven.png";
import img8 from "../../images/iconeight.png";
import img9 from "../../images/iconnine.png";
import img10 from "../../images/mobifirst.png"; 
import img11 from "../../images/mobisecond.png";
import img12 from "../../images/mobithird.png";
import img13 from "../../images/mobifour.png";
import img14 from "../../images/mobifive.png";
import img15 from "../../images/mobisix.png";
import img16 from "../../images/mobiseven.png";
import img17 from "../../images/mobieight.png";
import img18 from "../../images/comicon1.png";
import img19 from "../../images/comicon2.png";
import img20 from "../../images/comicon3.png";
import img21 from "../../images/comicon4.png";
import img22 from "../../images/comicon5.png";
import img23 from "../../images/iconsix.png";
import img24 from "../../images/comicon6.png";
import img25 from "../../images/comicon7.png";
import img26 from "../../images/comicon8.png";
import img27 from "../../images/blockicon1.png";
import img28 from "../../images/blockicon2.png";
import img29 from "../../images/blockicon3.png";
import img30 from "../../images/blockicon4.png";
import img31 from "../../images/blockicon5.png";
import img32 from "../../images/blockicon6.png";
import img33 from "../../images/blockicon7.png";
import img34 from "../../images/blockicon8.png";
import img35 from "../../images/blockicon9.png";
import img36 from "../../images/ioticon1.png";
import img37 from "../../images/ioticon2.png";
import img38 from "../../images/ioticon3.png";
import img39 from "../../images/ioticon4.png";
import img40 from "../../images/ioticon5.png";
import img41 from "../../images/ioticon6.png";
import img42 from "../../images/ioticon7.png";
import img43 from "../../images/ioticon8.png";
import img44 from "../../images/ioticon9.png";
import img45 from "../../images/dicon1.png";
import img46 from "../../images/dicon2.png";
import img47 from "../../images/dicon3.png";
import img48 from "../../images/dicon4.png";
import img49 from "../../images/dicon5.png";
import img50 from "../../images/dicon6.png";
import img51 from "../../images/dicon7.png";
import img52 from "../../images/dicon8.png";
import img53 from "../../images/dicon9.png";
import img54 from "../../images/cloudicon1.png";
import img55 from "../../images/cloudicon2.png";
import img56 from "../../images/cloudicon3.png";
import img57 from "../../images/cloudicon4.png";
import img58 from "../../images/cloudicon5.png";
import img59 from "../../images/cloudicon6.png";
import img60 from "../../images/cloudicon7.png";
import img61 from "../../images/cloudicon8.png";
import img62 from "../../images/cloudicon9.png";
import img63 from "../../images/iticon1.png";
import img64 from "../../images/iticon2.png";
import img65 from "../../images/iticon3.png";
import img66 from "../../images/iticon4.png";
import img67 from "../../images/iticon5.png";
import img68 from "../../images/iticon6.png";
import img69 from "../../images/iticon7.png";
import img70 from "../../images/iticon8.png";
import img71 from "../../images/iticon9.png";
import img72 from "../../images/uiicon1.png";
import img73 from "../../images/uiicon2.png";
import img74 from "../../images/uiicon3.png";
import img75 from "../../images/uiicon4.png";
import img76 from "../../images/uiicon9.png";
import img78 from "../../images/uiicon5.png";
import img79 from "../../images/uiicon6.png";
import img80 from "../../images/uiicon7.png";
import img81 from "../../images/uiicon8.png";



export const Webdata = [
  {
    img: img1,
    title: "Static & Dynamic Websites",
    para: "We build both static  and dynamic websites with consistent content ensuring flexibility and engagement for your audience",
  },

  {
    img: img2,
    title: "E-commerce Development",
    para: "Leverage our expert guidance to optimize your IT strategies and stay ahead of the curve.",
  },
  {
    img: img3,
    title: "Content Management Systems (CMS)",
    para: "Our CMS solutions, enabling easy content updates and management without needing to dive into code.",
  },
  {
    img: img4,
    title: "Progressive Web Apps (PWA)",
    para: "Our PWAs combine the best of mobile apps and websites, providing a fast, reliable, and engaging experience even with low internet connections.",
  },
  {
    img: img5,
    title: "Responsive Design & Development",
    para: "Our designs are mobile-first, ensuring seamless user experiences across all devices.",
  },
  {
    img: img6,
    title: "API Integration",
    para: "Enhance your website with third-party services using API integration, creating streamlined user experiences.",
  },
  {
    img: img7,
    title: "Web Performance Optimization",
    para: "We ensure fast loading times, optimized code, and minimal downtime to deliver the best performance.",
  },
  {
    img: img8,
    title: "Website Maintenance & Support",
    para: "From updates to bug fixes, we offer ongoing maintenance to keep your website secure and up-to-date.",
  },
  {
    img: img9,
    title: "Website Revamps",
    para: "Need a fresh look or added functionality? Our team is ready to give your website a modern makeover.",
  },
];


export const Mobdata = [
  {
    img: img10,
    title: "iOS App Development",
    para: "We specialize in creating high-quality iOS apps that leverage the full potential of Apple's ecosystem, ensuring seamless functionality and a refined user interface.",
  },

  {
    img: img11,
    title: "Android App Development",
    para: "Our Android app solutions are designed to offer excellent performance across a wide range of devices, ensuring consistency and scalability.",
  },
  {
    img: img12,
    title: "Hybrid Mobile Apps",
    para: "Combining the best of both worlds, we create hybrid apps that deliver a native-like experience with reduced development time and cost.",
  },
  {
    img: img13,
    title: "Wearable App Development",
    para: "We build innovative apps for wearable devices, keeping users connected while enhancing their experiences with cutting-edge technology.",
  },
  {
    img: img14,
    title: "Mobile UI/UX Design",
    para: "Our design-first approach ensures that your app not only looks great but provides a flawless user experience, keeping engagement at its core.",
  },
  {
    img: img15,
    title: "App Testing & QA",
    para: "Our thorough testing and QA services ensure that your app is robust, secure, and free from bugs, ready for launch.",
  },
  {
    img: img16,
    title: "Mobile App Maintenance",
    para: "We provide continuous support and maintenance to ensure your app stays updated and performs optimally as technology evolves.",
  },
  {
    img: img17,
    title: "App Store Optimization (ASO)",
    para: "We help improve your app’s visibility in app stores, driving organic downloads and enhancing user engagement.",
  },
  // {
  //   img: require("../../images/mobinine.png"),
  //   title: "Website Revamps",
  //   para: "Need a fresh look or added functionality? Our team is ready to give your website a modern makeover.",
  // },
];



export const customData = [
  {
    img: img18,
    title: "Enterprise Software Solutions",
    para: "Develop large-scale enterprise software to streamline operations, boost productivity, and improve decision-making.",
  },

  {
    img: img19,
    title: "SaaS Development",
    para: "We design and develop scalable SaaS applications that offer flexibility and convenience for your end-users.",
  },
  {
    img: img20,
    title: "CRM & ERP Solutions",
    para: "Empower your business with custom CRM and ERP systems that integrate smoothly into your existing processes and data workflows.",
  },
  {
    img: img21,
    title: "Business Automation Tools",
    para: "Automate repetitive tasks and workflows with business automation tools, designed to enhance efficiency and reduce human error.",
  },
  {
    img: img22,
    title: "Database Design & Development",
    para: "We create optimized and secure databases that are easy to manage and scale as your data grows.",
  },
  {
    img: img23,
    title: "Custom API Development",
    para: "Seamlessly integrate your software with third-party applications through custom API development, ensuring smooth communication between systems.",
  },
  {
    img: img24,
    title: "Desktop Application Development",
    para: "Build high-performance desktop applications for Windows and Mac, tailored to your specific business needs.",
  },
  {
    img: img25,
    title: "Third-Party Integrations",
    para: "Extend the functionality of your existing systems with reliable third-party integrations, ensuring minimal disruptions to your workflows.",
  },
  {
    img: img26,
    title: "Software Prototyping & MVP Development",
    para: "Rapidly develop software prototypes or MVPs to test and validate your ideas before full-scale development.",
  },
];


export const blockchainData = [
  {
    img: img27,
    title: "Smart Contract Development (Solidity, Ethereum)",
    para: "Automate agreements with secure, self-executing smart contracts on Ethereum and other blockchain platforms.",
  },

  {
    img: img28,
    title: "Decentralized Apps (DApps) Development",
    para: "Build decentralized applications to leverage the power of blockchain for transparency and security.",
  },
  {
    img: img29,
    title: "Token Development (ERC20, ERC721, BEP20)",
    para: "Create secure and scalable tokens for various blockchain standards including ERC20, ERC721, and BEP20.",
  },
  {
    img: img30,
    title: "Cryptocurrency Wallet Development",
    para: "Develop secure cryptocurrency wallets to manage digital assets with confidence.",
  },
  {
    img: img31,
    title: "Blockchain Consulting & Strategy",
    para: "Gain insights and strategies to implement blockchain solutions tailored to your business goals.",
  },
  {
    img:img32,
    title: "Private Blockchain Development",
    para: "Build customized, permissioned blockchain networks for secure, private transactions.",
  },
  {
    img: img33,
    title: "Blockchain Security Solutions",
    para: "Secure your blockchain infrastructure with our advanced security solutions, ensuring the integrity of your data.",
  },
  {
    img: img34,
    title: "Blockchain for Supply Chain Management",
    para: "Enhance traceability, efficiency, and transparency in supply chain operations with blockchain technology.",
  },
  {
    img: img35,
    title: "DeFi (Decentralized Finance) Solutions",
    para: "Empower your business with DeFi solutions that offer decentralized lending, borrowing, and asset management.",
  },
];


export const IotData = [
  {
    img: img36,
    title: "IoT Hardware Integration",
    para: "Seamlessly connect devices and hardware to create a unified, smart IoT system.",
  },

  {
    img: img37,
    title: "IoT Platform Development",
    para: "Develop scalable IoT platforms that manage devices, process data, and offer insightful analytics.",
  },
  {
    img: img38,
    title: "Smart Home Solutions",
    para: "Build secure, intelligent smart home systems with advanced IoT technology for enhanced convenience and efficiency.",
  },
  {
    img: img39,
    title: "Industrial IoT (IIoT) Solutions",
    para: "Optimize industrial operations through connected IIoT solutions, enabling real-time monitoring and automation.",
  },
  {
    img: img40,
    title: "Wearable IoT Solutions",
    para: "Design wearable IoT solutions that provide seamless connectivity and enhanced user experiences.",
  },
  {
    img: img41,
    title: "IoT Sensor Integration",
    para: "Integrate sensors into your IoT ecosystem for real-time data collection and analysis.",
  },
  {
    img: img42,
    title: "IoT Data Analytics & Insights",
    para: "Leverage IoT data analytics to gain actionable insights and improve decision-making.",
  },
  {
    img: img43,
    title: "Edge Computing Solutions",
    para: "Enable faster processing and reduced latency with edge computing for IoT applications.",
  },
  {
    img: img44,
    title: "IoT Mobile & Web App Development",
    para: "Develop mobile and web applications to control, monitor, and manage IoT devices with ease.",
  },
];

export const digitalData = [
  {
    img: img45,
    title: "Search Engine Optimization (SEO)",
    para: "Improve your website’s visibility on search engines, driving more organic traffic.",
  },

  {
    img: img46,
    title: "Pay-Per-Click (PPC) Advertising",
    para: "Run targeted PPC campaigns on Google and Bing for instant visibility and higher conversions.",
  },
  {
    img: img47,
    title: "Social Media Marketing",
    para: "Build your brand’s presence across social media platforms with targeted strategies.",
  },
  {
    img: img48,
    title: "Email Marketing",
    para: "Engage your audience with personalized email campaigns that drive conversions.",
  },
  {
    img: img49,
    title: "Content Marketing (Blogging, Articles, E-books)",
    para: "Create valuable content that attracts, engages, and converts your target audience.",
  },
  {
    img: img50,
    title: "Affiliate Marketing",
    para: "Expand your reach and generate sales through a network of affiliate partners.",
  },
  {
    img: img51,
    title: "Influencer Marketing",
    para: "Collaborate with influencers to promote your products and build trust with new audiences.",
  },
  {
    img: img52,
    title: "Conversion Rate Optimization (CRO)",
    para: "Maximize the value of your website traffic by optimizing user experience and increasing conversions.",
  },
  {
    img: img53,
    title: "Online Reputation Management (ORM)",
    para: "Protect and enhance your brand’s online reputation with effective reputation management strategies.",
  },
];

export const cloudData = [
  {
    img: img54,
    title: "Cloud Infrastructure Setup (AWS, Azure, Google Cloud)",
    para: "Set up cloud infrastructure that scales effortlessly, ensuring high availability and performance.",
  },

  {
    img: img55,
    title: "Cloud Migration Services",
    para: "Seamlessly move your business to the cloud with minimal disruption and maximum security.",
  },
  {
    img: img56,
    title: "Hybrid Cloud Solutions",
    para: "Combine the best of private and public clouds for increased flexibility and control.",
  },
  {
    img: img57,
    title: "Cloud-Native Application Development",
    para: "Build scalable, secure applications that leverage cloud-native features for optimal performance.",
  },
  {
    img: img58,
    title: "Cloud Cost Optimization",
    para: "Maximize your cloud investment by identifying inefficiencies and optimizing costs.",
  },
  {
    img: img59,
    title: "Cloud Backup & Disaster Recovery",
    para: "Ensure business continuity with secure, automated cloud backups and disaster recovery plans.",
  },
  {
    img: img60,
    title: "Cloud Monitoring & Maintenance",
    para: "Proactively monitor your cloud infrastructure to ensure performance and reliability.",
  },
  {
    img: img61,
    title: "Serverless Architecture Development",
    para: "Build and deploy applications without managing servers, reducing operational complexity.",
  },
  {
    img: img62,
    title: "Cloud DevOps Services (CI/CD Pipelines)",
    para: "Automate and streamline development processes with CI/CD pipelines, ensuring faster release cycles.",
  },
];

export const ItData = [
  {
    img: img63,
    title: "IT Strategy Consulting",
    para: "Align your IT strategy with business goals to enhance performance and scalability.",
  },

  {
    img: img64,
    title: "Technology Roadmap Development",
    para: "Develop a technology roadmap that guides your digital transformation initiatives.",
  },
  {
    img: img65,
    title: "Cloud Infrastructure Consulting",
    para: "Leverage cloud technologies to optimize operations and reduce IT infrastructure costs.",
  },
  {
    img: img66,
    title: "IT Budgeting & Cost Optimization",
    para: "Build scalable, secure applications that leverage cloud-native features for optimal performance.",
  },
  {
    img: img67,
    title: "Software & Platform Selection",
    para: "Get expert advice on selecting the right software and platforms to meet your business needs.",
  },
  {
    img: img68,
    title: "Data & Analytics Consulting",
    para: "Unlock the power of data with tailored analytics strategies that drive informed decision-making.",
  },
  {
    img: img69,
    title: "Digital Transformation Consulting",
    para: "Lead your business into the future with digital transformation strategies that modernize operations.",
  },
  {
    img:img70,
    title: "IT Project Management",
    para: "Ensure successful IT projects from start to finish with expert project management support.",
  },
  {
    img: img71,
    title: "Risk Assessment & Compliance Consulting",
    para: "Identify and mitigate risks while ensuring compliance with industry standards and regulations.",
  },
];

export const uiuxData = [
  {
    img: img72,
    title: "User Research & Personas",
    para: "Understand your target users and create personas to inform design decisions.",
  },

  {
    img: img73,
    title: "Wireframing & Prototyping",
    para: "Visualize your product’s structure with interactive wireframes and prototypes for a refined user experience.",
  },
  {
    img: img74,
    title: "Mobile App UI/UX Design",
    para: "Design user-friendly mobile apps that are both intuitive and visually appealing.",
  },
  {
    img: img75,
    title: "Web UI/UX Design",
    para: "Create seamless and engaging web experiences through responsive and user-focused designs.",
  },
  {
    img: img76,
    title: "Interaction Design",
    para: "Enhance usability with intuitive interactions that keep users engaged.",
  },
  {
    img: img78,
    title: "Usability Testing",
    para: "Test your product’s functionality and ensure it meets user needs effectively.",
  },
  {
    img: img79,
    title: "Design Systems & Style Guides",
    para: "Ensure design consistency with comprehensive design systems and style guides.",
  },
  {
    img: img80,
    title: "Product Design",
    para: "From MVP to full-scale products, we design with scalability and usability in mind.",
  },
  {
    img: img81,
    title: "Responsive Design",
    para: "Guarantee a smooth and consistent experience across all devices with responsive design solutions.",
  },
];
