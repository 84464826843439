import { Card } from "react-bootstrap";
import "./Technologies.css";

import { techItemsArray } from "./TechItems";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Technologies = ({ click }) => {
  // let width = window.innerWidth;
  // let number;
  // if (width > 480) {
  //   number = 7;
  // } else {
  //   number = 8;
  // }
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const location = useLocation();
  const currentPath = location.pathname;


   const navigate = useNavigate();
   const handleCardClick = (route) => {
     window.scrollTo(0, 0); 
     navigate(route);
   };
  useEffect(() => {
    console.log("currentPath", currentPath);
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [location.pathname]); // Empty dependency array ensures the effect runs only once after initial render
  // explore all technologies
  return (
    <>
    
      <div className="d-flex flex-wrap mb-md-5 my-3 mt-md-0 tech-card-section b ">
        {techItemsArray.map((data, i) => {
          return (
            <div
              className="col-md-4 col-6 my-md-2 my-1 px-xl-5 px-lg-3 px-1"
              key={i}
              // onClick={() => handleCardClick(data.route)}
              // style={{ cursor: "pointer" }}
            >
              <div
                className="tech-card"
                onClick={() => handleCardClick(data.route)}
                style={{ cursor: "pointer" }}
              >
                <div className="unflipped flip">
                  <Card className="border-radius p-2">
                    <Card.Img
                      loading="lazy"
                      alt="Card Img"
                      variant="top"
                      src={data.img}
                      height={265}
                    />
                  </Card>
                  <div className="card-text text-white col-10">
                    <div className="bg-white rounded shadow">
                      <h6 className="unflipped title-heading p-md-3 pt-2 pb-2 cardtext ">
                        {data.title}
                      </h6>
                    </div>
                  </div>
                </div>

                {/* flipped card */}
                <div className="flipped-card d-none flip">
                  <Card className="border-radius">
                    <Card.Body>
                      <div className="inside-card mt-md-3">
                        <div className="icon mb-md-4 mb-2">
                          <img src={data.flippedImg} alt="Icon" width="120%" />
                        </div>
                        <h5 className="flipped title-heading">
                          {data.flippedTitle}
                        </h5>
                        <p>{data.flippedText}</p>
                      </div>

                      <div className="card-flip-text col-10">
                        <div className="bg-white rounded shadow">
                          <h6 className="flipped title-heading p-md-3 pt-2 pb-2">
                            {data.title}
                          </h6>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Technologies;
