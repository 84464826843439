import React from 'react'
import img1 from "../images/ML.png";
import './ML.css';

export default function Services() {
  return (
    <div className="container mt-4">
      <div className="row px-2">
        {/* Image section with bottom margin */}
        <div className="col-12 col-md-5 mb-4 mt-4">
          <img
            src={img1}
            alt="Image describing innovation in tech"
            className="img-fluid"
          />
        </div>
        {/* Text section with responsive margin */}
        <div className="col-12 col-md-6 mt-lg-4 ms-md-5"> {/* On mobile, text takes full width */}
          <div className='col-md-8'>
            <h1>
              <span>Our Comprehensive</span><br />
              <span className='tColor'>AI / ML </span>Services
            </h1>
          </div>
          <div className='mt-3'>
            <p className=' text-muted'>
              With years of experience and a talented team of 30+ engineers, we deliver AI development services and solutions that are user-friendly and feature-rich. Our expertise reaches far beyond just one industry, as we’ve built software for fintech, healthcare, entertainment, and more. We maintain transparent communication to ensure you’re always in the loop.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
