import React, { useState, useEffect } from "react";
import "./home.css";
import guide from "../images/guide.png";
import articon from "../images/arcticons_security.png";
import electron from "../images/vector.png";
import circum from "../images/circum_server.png";
import arrow from "../images/ph_arrow-up-light.png";
import cloud from "../images/mdi-light_cloud.png";
import { FaArrowRight } from "react-icons/fa6";

import Technologies from "../components/Common/Technologies";
// import Solutions from "../components/Common/Solutions";
// import { MdArrowOutward } from "react-icons/md";

import Banner from "../images/homeImages/home-bannerbig.png";
import BannerMob from "../images/homeImages/bannermob.png";
import TechDown from "../images/techImages/tech-down-img.png";

import Customer from "../components/Common/Customer";
import Testimonials from "../components/Common/Testimonials";
import Stats from "../components/Common/Stats";
import CssSwiper from "../components/Common/CssSwiper";
import { IoMdNavigate } from "react-icons/io";
import ProjectShow from "../components/Common/ProjectShow";
import { useNavigate } from "react-router-dom";

export default function Home() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures the effect runs only once after initial render
  // explore all technologies
  const [
    click,
    // eslint-disable-next-line
    setClick,
  ] = useState(true);

  // Explore all solutions
  const [
    solution,
    // eslint-disable-next-line
    setSolution,
  ] = useState(true);

  const navigate=useNavigate();

  const handleNavigate=()=>{
    navigate("/contact-us");
  }

  return (
    <>
      {/* Banner  */}

      <div className="bannerrole d-md-block d-none">
        <div className="d-flex flex-wrap align-items-center bannerbackground">
          <div className="col-md-7 ">
            <div className="banner-box1 ">
              <div className="">
                <h4 className="mx-1">Innovate. Integrate. Excel </h4>
                <h1 className=" textheading">
                  Driving Excellence Through Customized Solutions
                </h1>
              </div>
            </div>
            <div className="d-flex justify-content-evenly mt-md-5 pt-5 mx-md-4 p-3 ">
              <div className="col-3">
                <h4>10+ Industries</h4>
                <p>Covered across the globe</p>
              </div>
              <div className="col-3">
                <h4>95%</h4>
                <p>Customer Satisfaction Rate</p>
              </div>
              <div className="col-4">
                <h4>100%</h4>
                <p>
                  Scalability Guaranteed, ensuring complete future-readiness.
                </p>
              </div>
            </div>
            <div className=" clientimgdiv p-2 mt-md-3">
              <img
                src={require("../images/bannerlogo.png")}
            className="img-fluid col-md-7 "
                alt="bannclient"
              />
              <p className="mt-3 mx-2 ">
                {/* <b>10k+</b> Satisfied Client */}
                Trusted by Industry Leaders Across <br />
                10+ Sectors
              </p>
            </div>
          </div>
          <div className="col-md-5 px-md-4 ">
            <img
              src={require("../images/bannerside.png")}
              className="img-fluid  p-md-4 pb-md-0"
            />
            <div className=" d-flex justify-content-end align-items-center">
              <div
                className="btn border col-4  mt-md-4 rounded-5 abobutton p-2"
                onClick={handleNavigate}
              >
                Get in touch
              </div>
              <div onClick={handleNavigate}>
                <img
                  src={require("../images/homeImages/aboutround.png")}
                  className=" img-fluid  col-7 mt-md-4  "
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {windowWidth < 480 && (
        <>
          <div className="d-flex flex-wrap">
            <div className="mob-bg-banner">
              <div className="p-4 pb-0">
                <h4 className="">Innovate. Integrate. Excel </h4>
                <h1 className="formobhead">
                  Driving Excellence Through Customized Solutions
                </h1>
                <div className="d-flex p-1 mt-4">
                  <div className="col-5">
                    {" "}
                    <h6>10+ Industries</h6>
                    <p
                      className="fontformob p-0 m-0"
                      style={{ lineHeight: "1" }}
                    >
                      Covered across countries
                    </p>
                  </div>
                  <div>
                    <h6>95%</h6>
                    <p
                      className="fontformob p-0 m-0"
                      style={{ lineHeight: "1" }}
                    >
                      Customer Satisfaction Rate
                    </p>
                  </div>
                  <div className="col-4 mx-3">
                    <h6>100%</h6>
                    <p
                      className="fontformob p-0 m-0"
                      style={{ lineHeight: "1" }}
                    >
                      Scalability Guarantee, ensuring future-readiness.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <img
                src={require("../images/bannerside.png")}
                className="img-fluid  p-4 pb-md-0"
                alt="mobsidebann"
              />
              {/* <div className="text-end me-4  ">
                <button
                  className="p-2 px-4 rounded-5 bannerbuton"
                  onClick={handleNavigate}
                >
                  Get in touch <FaArrowRight className="mx-md-2 " />
                </button>
              </div> */}
              <div className=" mx-3">
                <img
                  src={require("../images/bannerlogo.png")}
                  className="img-fluid col-9"
                  alt="bannclient"
                />
              </div>
              <div className=" d-flex align-items-center justify-content-center mt-4">
                <div
                  className="btn border col-7  mt-md-4 rounded-5 abobutton "
                  onClick={handleNavigate}
                >
                  Get in touch
                </div>
                <div onClick={handleNavigate}>
                  <img
                    src={require("../images/homeImages/aboutround.png")}
                    className=" img-fluid  col-7 mt-md-4 "
                    alt="roundedicon"
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {/* Technologies  */}
      <div className="uncover-expertise">
        <div className="container  tech">
          <div className="col-12 tech-heading text-center">
            <div className="pt-5 pt-md-0">
              <h1 className="pt-5 ">Explore Our Expertise</h1>
            </div>
            <p className="secondary-light-color mb-lg-5 mb-3 pforimac">
              Your IT journey starts here: uncover expert solutions crafted to
              elevate your business growth. Experience customized services for
              your success story.
            </p>
          </div>
          {/* <div
            className={`${
              windowWidth > 2520 ? "container-fluid" : "container"
            }`}
          ></div> */}
          <Technologies />
          {/* <Technologies click={click} /> */}
          <div className={`text-center ${click ? "" : "d-none"}`}></div>
        </div>
      </div>

      {/* <div className="bgabout p-2">
        <div className="d-flex justify-content-evenly flex-wrap align-items-center px-5">
          <div className="col-md-8 ">
            <p className="col-md-7 fs-4 mx-md-5">
              We're strategic digital marketers collaborating worldwide with top
              brands. Progress comes from daring to be bold. We merge ideas and
              actions for impactful results.
            </p>
            <div className="d-flex align-items-center  justify-content mx-md-5">
            <div className="btn border col-2  mt-md-4 rounded-5 abobutton">
              Know About us
            </div>
            <div className="">
            
              <img src={require("../images/homeImages/aboutround.png")} className="img-fluid  col-7 mt-md-4 "/>
            </div>
            </div>
          </div>
          <div className="col-md-4 p-3 ">
            <img
              src={require("../images/homeImages/aboutimage.png")}
              className="img-fluid rounded-4"
            />
          </div>
        </div>
      </div> */}

      {/* Solutions  */}
      {/* <div className="container-fluid mt-md-3 mt-3 pt-4 solution">
        <div className="col-12 solution-heading text-center">
          <h1 className="my-3">Solutions We Deliver</h1>
          <p className="secondary-light-color mb-lg-5 pforimac">
            Effortlessly manage your cloud and on-premises systems with our
            comprehensive support for infrastructure and applications.
          </p>
        </div>
        <Solutions solution={solution} />
        <div className={`text-center pt-4 ${solution ? "" : "d-none"}`}>
          <a
            className="btn btn-secondary rounded-5 px-md-5 px-3 py-2 text-center"
            href="/solutions"
          >
            Explore all Solutions
          </a>
        </div>
      </div> */}

      {/* Stats  */}

      {/* _____________________________________________ */}

      {/* ClientSpotLight */}

      <Stats />
      <CssSwiper />

      {/* Ecosystem  */}
      {/* <div className="bg-ecosystem mt-5">
        <div className="container d-flex flex-wrap align-items-center eco-box">
          <div className="col-md-4 ps-lg-5 px-md-0 px-4">
            <h1 className=" mb-md-4">Your IT Ecosystem, Perfectly Managed</h1>
            <p className="mb-0 secondary-light-color">
              Effortlessly manage your cloud and on-premises systems with our
              comprehensive support for infrastructure and applications.
            </p>
          </div>
          <div className="col-md-8">
            <div className="row d-flex flex-wrap justify-content-center">
              <div className="fold-paddd col-md-6 col-12 px-lg-4 pe-md-0 ps-md-5 px-5 py-5 pt-md-0">
                <div className="box-style px-3">
                  <div className="d-flex justify-content-between">
                    <h4 className="pt-4 mb-3">Consulting</h4>
                    <img  alt="" src={guide} width={48} height={48} className="mt-3 me-3 air-styl" />
                  </div>
                  <p className="mb-0 secondary-light-color mt-2">
                    Leverage our expert guidance to optimize your IT strategies
                    and stay ahead of the curve.
                  </p>
                </div>
              </div>
              <div className="fold-paddd col-md-6 col-12 px-lg-4 pe-md-0 ps-md-5 px-5 pb-5">
                <div className="box-style px-3">
                  <div className="d-flex justify-content-between ">
                    <h4 className="pt-4 mb-3">Security</h4>
                    <img
                      alt=""
                      src={articon}
                      width={48}
                      height={48}
                      className="mt-3 me-3 air-styl"
                    />
                  </div>
                  <p className="mb-0 secondary-light-color mt-2">
                    Strengthen your digital assets with our robust security
                    solutions, safeguarding your business from potential
                    threats.
                  </p>
                </div>
              </div>
              <div className="fold-paddd col-md-6 col-12 px-lg-4 pe-md-0 ps-md-5 px-5 pb-5 pb-md-0 ">
                <div className="box-style px-3">
                  <div className="d-flex justify-content-between">
                    <h4 className="pt-4 mb-3">Implementation</h4>
                    <img  alt=""
                      src={electron}
                      width={48}
                      height={48}
                      className="mt-3 me-3 air-styl"
                    />
                  </div>
                  <p className="mb-0 secondary-light-color mt-2">
                    Seamlessly integrate new technologies into your operations,
                    enhancing efficiency and productivity
                  </p>
                </div>
              </div>
              <div className="fold-paddd col-md-6 col-12 px-lg-4 pe-md-0 ps-md-5 px-5 ">
                <div className="box-style px-3">
                  <div className="d-flex justify-content-between">
                    <h4 className="pt-4 mb-3">Migration</h4>
                    <div className="d-flex justify-content-between mt-2 me-3">
                      <img  alt="" src={circum} width={32} height={32} className="mt-3 me-1 air-styl" />
                      <img  alt="" src={arrow} width={12} height={12} className="mt-4 me-1 air-styl" />
                      <img  alt="" src={cloud} width={32} height={32} className="mt-3 air-styl" />
                    </div>
                  </div>
                  <p className="mb-0 secondary-light-color mt-2">
                    Ensure a smooth transition to advanced systems with our
                    seamless migration services, minimizing disruption and
                    downtime.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* why choose us  */}
      <div className="d-flex  flex-wrap align-items-center bgdiv">
        <div className="col-md-6 order-mobile-2 pb-md-0 pb-4 ">
          <div className="mx-4 mt-3">
            <h1 className="mx-md-4 mx-4">Why Choose Us?</h1>
            <p className="col-md-9 px-2 mt-3 mx-3 text-muted  pforimac">
              Elevate your business with our unmatched IT solutions, crafted to
              empower your growth and achieve remarkable success.
            </p>
          </div>
          <div className="">
            <div className="d-flex mt-md-5 mt-3 mx-md-5 mx-5  align-items-center ">
              <div className="col-md-1 col-2">
                {/* <IoMdNavigate
                className="navarrow mx-md-3"
                fill="white"
                size={20}
              />{" "} */}
                <img
                  src={require("../images/homeImages/choose1.png")}
                  className="img-fluid p-2"
                  alt="imgchoose"
                />
              </div>
              <div className=" col-md-6 rounded-end-2 p-2 col-12 ">
                <p className=" mx-2 pforimac">Customer-Centric Approach</p>
              </div>
            </div>

            <div className="d-flex  mt-md-3  mt-3 mx-md-5 mx-5 align-items-center">
              <div className="col-md-1 col-2">
                {/* <IoMdNavigate
                className="navarrow mx-md-3"
                fill="white"
                size={20}
              />{" "} */}
                <img
                  src={require("../images/homeImages/choose2.png")}
                  className="img-fluid p-2"
                  alt="imgchoose1"
                />
              </div>
              <div className=" col-md-6 rounded-end-2 p-2 col-12">
                <p className="mx-2  pforimac">High Quality Work</p>
              </div>
            </div>

            <div className="d-flex  mt-md-3 mt-3 mx-md-5 mx-5 align-items-center ">
              <div className="col-md-1 col-2">
                {/* <IoMdNavigate
                className="navarrow mx-md-3"
                fill="white"
                size={20}
              />{" "} */}
                <img
                  src={require("../images/homeImages/choose3.png")}
                  className="img-fluid p-2"
                  alt="imgchoose2"
                />
              </div>
              <div className=" col-md-6 rounded-end-2 p-2 col-12">
                <p className=" mx-2 pforimac">Ethical & Professional</p>
              </div>
            </div>
          </div>
          {/* <img src={require("../images/homeImages/chooseusimage.png")} className="img-fluid w-100 p-md-0 p-2" /> */}
        </div>
        <div className="col-md-6 px-1 mt-md-0 mt-4  order-mobile-1">
          <img
            loading="lazy"
            src={require("../images/homeImages/Rectangle11.png")}
            alt=""
            className="img-fluid w-100 p-md-0 p-2"
          />
        </div>
      </div>

      {/* after choose  */}

      {/* Success stories */}
      {/* <div className="back-success"> */}
      <div className="text-center">
        {/* <p className="pt-5 secondary-light-color pforimac">Our Projects</p> */}
        <h1 className=" mt-5 m-0 p-0">Recent Clients Projects</h1>
        {/* </div> */}

        {/* <Customer /> */}
        {/* <Animation /> */}
        <ProjectShow />
      </div>

      <div
        className={`${
          windowWidth > 2520 ? "container-fluid" : "container"
        }   mb-5`}
      >
        <div className="text-center">
          <p className="mt-5 secondary-light-color pforimac">Testimonials</p>
          <h1 className="mb-5 mt-3">Client Voices</h1>
        </div>

        <Testimonials />
      </div>
    </>
  );
}
