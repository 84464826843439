import React from 'react'
import img1 from "../images/image.png";
import img2 from "../images/Frame.svg";

export default function What() {
  return (
    <div className="container mt-4">
      <div className="row px-2">
        {/* Image section with bottom margin */}
        <div className="col-12 col-md-5 mb-4 mt-4">
          <img
            src={img1}
            alt="Image describing innovation in tech"
            className="img-fluid"
          />
        </div>
        {/* Text section with responsive margin */}
        <div className="col-12 col-md-6 mt-lg-4 ms-md-5"> {/* On mobile, text takes full width */}
          <div className='col-md-12'>
            <h1>
            What is Generative AI?
            </h1>
          </div>
          <div className='mt-3'>
            <p className='col-11'>
            It’s like a smart assistant that learns from existing data and generates something entirely new, tailored to your needs. Generative AI is a cutting-edge technology that uses advanced algorithms to create new content like text, images, music, or even code.
            </p>
          </div>
          <div className="d-flex align-items-center mb-4 mt-3">
            <img src={img2} alt="logo 1" className="img-fluid" />
            <p className="ms-3">AI-powered automation</p>
          </div>
          <div className="d-flex align-items-center mb-4 mt-3">
            <img src={img2} alt="logo 1" className="" />
            <p className="ms-3">Faster, Scalable & Cost-Efficient</p>
          </div>
          <div className="d-flex align-items-center mb-4 mt-3">
            <img src={img2} alt="logo 1" className="img-fluid" />
            <span className="ms-3">Enhancing Creativity & Business Intelligence</span>
          </div>

        </div>
      </div>
    </div>
  )
}
