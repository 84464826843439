import React from 'react';
import './ML.css';
import { useNavigate } from 'react-router-dom';

export default function GenAi() {
  const navigate = useNavigate();

  const handleContact = () => {
    window.scrollTo(0,0)
    navigate("/contact-us");
  };

  return (
    <div className="background3 text-light d-flex align-items-center justify-content-center ">
      <div className="container">
        <div className="row align-items-center">
          {/* Text Section */}
          <div className="col-12 col-md-6 text-start">
            <h1 >Outrank the competition with AI & ML services</h1>
            <p className="mt-3 ">
            Machine learning solutions are transforming business capabilities. Reimagine what's possible, and leave the competition behind.
            </p>
            <div className="d-flex">
                <div
                  className="btn border col-lg-3 col-5  mt-md-4 mt-4 rounded-5 abobutton"
                  onClick={handleContact}
                >
                  Contact us
                </div>
                <div onClick={handleContact}>
                  <img
                    src={require("../images/homeImages/aboutround.png")}
                    className="img-fluid  col-7 mt-md-4 mt-4 "
                  />
                </div>
              </div>
          </div>

          
        </div>
      </div>
    </div>
  );
}
