import React from 'react'
import CssSwiper from '../components/Common/CssSwiper'
import CardUi from '../components/Common/CardUi';
import './Webdevelopment.css'
import { useState } from 'react';
import { useEffect,useRef } from 'react';
import img1 from '../images/webbannerimg.png'
import { FaArrowTrendUp } from "react-icons/fa6";
import icon from "../images/homeImages/icon-stats.png";
import { Webdata } from '../components/Common/CardwebData';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
 import {useNavigate} from 'react-router-dom'
import Expertise from '../components/Common/Expertise';
import Stats from '../components/Common/Stats';
import Static from './Static';
import {Helmet } from 'react-helmet';
// import img1 from '../images/datartswipe.png'

gsap.registerPlugin(ScrollTrigger);

function Webdevelopment() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures the effect runs on



  useEffect(() => {
    gsap.to(logoRef.current, {
      y: () => document.querySelector(".scroll-container").clientHeight - 100,
      ease: "none",
      scrollTrigger: {
        trigger: ".scroll-container",
        start: "top top",
        end: "bottom bottom",
        scrub: true,
      },
    });
  }, []);
 const logoRef = useRef(null);

   const navigate = useNavigate();

 const handleContact=()=>{
  navigate("/contact-us");
 }


  return (
    <div>
      <Helmet>
        <title>Datart Infotech | Experts in Web Design and Development</title>
        <meta
          name="description"
          content="We offer professional website design and development services. Build
          fast, secure, and user-friendly websites tailored to your business
          needs. Contact Us Today!"
        />
        <meta
          name="keywords"
          content="web development company, website design company, web design and development company, web development services, web design services"
        />
      </Helmet>
      {/* bannersection */}
      <div className="bannerrole d-md-block d-none ">
        <div className="d-flex flex-wrap align-items-center webbaner">
          <div className="col-md-7 col-12">
            <div className="webbanner-box ">
              <h1 className=" textheadingweb">
                {/* Driving Excellence Through Customized Solutions */}
                Building Future-Proof Websites with{" "}
                <span className="texthead">Web Development Solutions</span>
              </h1>

              <p className="mt-md-4 textwebpara text-muted pforimacweb">
                Our web development services provide scalable, secure, and fast
                solutions tailored to meet the needs of businesses in today's
                digital landscape. From design to deployment, we ensure your
                website remains modern and responsive, giving you a competitive
                edge online.
              </p>
              {/* <div>
                <button
                  className="btn bg-white mt-md-5 rounded-5 px-5 getaquote"
                  onClick={handleContact}
                >
                  Contact us
                </button>
              </div> */}

              <div className="d-flex">
                <div
                  className="btn border col-3  mt-md-4 rounded-5 abobutton"
                  onClick={handleContact}
                >
                  Contact us
                </div>
                <div onClick={handleContact}>
                  <img
                    src={require("../images/homeImages/aboutround.png")}
                    className="img-fluid  col-7 mt-md-4 "
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-5 px-md-4 col-12 mt-md-4 ">
            <img
              src={require("../images/webbannerside.png")}
              className="img-fluid  p-md-4 pb-md-0"
            />
          </div>
        </div>
      </div>

      {windowWidth < 480 && (
        <>
          <div className="webbaner">
            <div className="p-5 mx-3 pb-0">
              <h1 className="" style={{ textAlign: "center" }}>
                Building Future-Proof Websites with{" "}
                <span className="texthead">Web Development Solutions</span>
              </h1>
            </div>
            <div>
              <img
                src={require("../images/webmobsideban.png")}
                className="img-fluid mx-5 "
                alt="img"
              />
            </div>
            <p
              className="text-secondary px-4 mt-2"
              style={{ textAlign: "justify" }}
            >
              Our web development services provide scalable, secure, and fast
              solutions tailored to meet the needs of businesses in today's
              digital landscape. From design to deployment, we ensure your
              website remains modern and responsive, giving you a competitive
              edge online.
            </p>

            {/* <div className="text-center mt-2">
              <button
                className="btn bg-white mt-md-5 rounded-5 px-5"
                onClick={handleContact}
              >
                Contact us
              </button>
            </div> */}
            <div className="d-flex justify-content-center align-items-center mt-3">
              <div
                className="btn border col-5  mt-md-4 rounded-5 abobutton"
                onClick={handleContact}
              >
                Contact us
              </div>
              <div onClick={handleContact}>
                <img
                  src={require("../images/homeImages/aboutround.png")}
                  className="img-fluid  col-7 mt-md-4 "
                />
              </div>
            </div>
          </div>
        </>
      )}

      <div
        className="scroll-container  d-md-block d-none position-relative"
        // style={{ height: "1680px", position: "relative" }}
      >
        <div
          className=""
          style={{
            position: "absolute",
            left: "35px",
            top: "60px",
            bottom: "0px",
            width: "7px",
            backgroundColor: "#DAF0FF",
            zIndex: 1,
          }}
        >
          <div
            ref={logoRef}
            style={{
              width: "32px",
              height: "32px",
              backgroundColor: "#DAF0FF",
              borderRadius: "50%",
              position: "absolute",
              top: "0px",
              bottom: "0px",
              left: "-13px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#fff",
              fontWeight: "bold",
              zIndex: 2,
            }}
          >
            <img
              src={require("../images/datartswipe.png")}
              className="img-fluid"
            />
          </div>
        </div>

        <div className="content " style={{ marginLeft: "100px" }}>
          <div className="p-md-5 pb-md-0 p-3">
            <h3>Web development</h3>
            <img
              src={require("../images/webdevban.png")}
              alt="Webdev banner"
              className="col-12 pb-md-0"
            />
          </div>
          <CardUi dataarr={Webdata} />
          {/* stats */}
          <Static />

          {/* expertise */}
          <Expertise />
        </div>
      </div>

      {/* for mobile */}
      {windowWidth < 480 && (
        <>
          <div>
            <div className="p-2">
              <h3>Web development</h3>
              <img
                src={require("../images/webdevban.png")}
                alt="Webdev banner"
                className="col-12 pb-md-0"
                style={{ height: "150px" }}
              />
            </div>
            <CardUi dataarr={Webdata} />
            {/* stats */}
            <Static />

            {/* expertise */}
            <Expertise />
          </div>
        </>
      )}

      {/* client */}
      <CssSwiper />
    </div>
  );
}

export default Webdevelopment