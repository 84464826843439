import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Benefits.css';

import img1 from "../images/Efficiency.svg";
import img2 from "../images/Insight.svg";
import img3 from "../images/Adap.svg";
import img4 from "../images/Person.svg";
import img5 from "../images/Scale.svg";
import img6 from "../images/Precision.svg";

export default function Benefits() {
    return (
      <div className='bg-container'>
          <div className="container py-5">
            {/* Heading Section */}
            <div className="text-center">
                <h1 className="fw-bold">
                    Explore the <span className="tColor">Benefits</span> of Generative AI
                </h1>
                <p className="col-lg-10 col-md-11 col-12 mx-auto mt-3 fs-5">
                    Generative AI isn't just about automation; it's about unlocking creativity and efficiency in ways you never thought possible. It's about empowering you to do more, faster, and smarter.
                </p>
            </div>

            {/* Benefits Grid (Two Benefits per Row) */}
            <div className="row justify-content-center mt-5">
                {/* Row 1 */}
                <div className="col-md-5 d-flex align-items-start mb-4">
                    <img src={img1} alt="Efficiency" width="50" className="me-3" />
                    <div>
                        <h4 className="fw-bold">Efficiency</h4>
                        <p>With AI handling the heavy lifting, your team can focus on what truly matters—growing your business and delighting your customers.</p>
                    </div>
                </div>
                <div className="col-md-5 d-flex align-items-start mb-4">
                    <img src={img4} alt="Personalization" width="50" className="me-3" />
                    <div>
                        <h4 className="fw-bold">Personalization</h4>
                        <p>Generative AI helps you create content, recommendations, and solutions that resonate on a personal level, building stronger connections and loyalty.</p>
                    </div>
                </div>

                {/* Row 2 */}
                <div className="col-md-5 d-flex align-items-start mb-4">
                    <img src={img2} alt="Insights" width="50" className="me-3" />
                    <div>
                        <h4 className="fw-bold">Insights</h4>
                        <p>Turn overwhelming data into clear, actionable insights. Whether it’s spotting trends, predicting customer behavior, or optimizing operations, AI gives you the clarity to make smarter decisions.</p>
                    </div>
                </div>
                <div className="col-md-5 d-flex align-items-start mb-4">
                    <img src={img5} alt="Scalability" width="50" className="me-3" />
                    <div>
                        <h4 className="fw-bold">Scalability</h4>
                        <p>Scale your operations seamlessly, maintaining quality standards while controlling costs, to navigate the ever-changing market landscape with agility and resilience.</p>
                    </div>
                </div>

                {/* Row 3 */}
                <div className="col-md-5 d-flex align-items-start mb-4">
                    <img src={img3} alt="Adaptability" width="50" className="me-3" />
                    <div>
                        <h4 className="fw-bold">Adaptability</h4>
                        <p>Markets shift, trends change, and customer needs evolve. With AI, your business can stay ahead by learning and adapting in real-time—giving you an edge over competitors.</p>
                    </div>
                </div>
                <div className="col-md-5 d-flex align-items-start mb-4">
                    <img src={img6} alt="Precision" width="50" className="me-3" />
                    <div>
                        <h4 className="fw-bold">Precision</h4>
                        <p>From crafting flawless content to predicting outcomes with accuracy, Generative AI ensures every detail is just right—saving you time, money, and headaches.</p>
                    </div>
                </div>
            </div>
        </div>  
      </div>
    );
}
